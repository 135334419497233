import React, { useEffect, useState } from 'react';
import Hero4 from '../Hero/Hero4';
import Hero from '../Hero';
import { pageTitle } from '../../helper';
import Div from '../Div';
import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';
import MovingText2 from '../MovingText/MovingText2';
import Portfolio from '../Portfolio/Portfolio4';
import MovingText from '../MovingText';
import Cta from '../Cta';
import LogoList from '../LogoList';

const serviceData1 = [
  {
    title: 'Marketing',
    //href: '/service/service-details',
  },
  {
    title: 'Video',
    //href: '/service/service-details',
  },
  {
    title: 'Branding',
    //href: '/service/service-details',
  },
];
const serviceData2 = [
  {
    title: 'Web Site',
    //href: '/service/service-details',
  },
  {
    title: 'App design',
    //href: '/service/service-details',
  },
  {
    title: 'Custom App Developer',
    //href: '/service/service-details',
  },
];
const portfolioData = [
  {
    title: 'Finieco',
    subtitle: '',
    href: '',
    src: '9CLsIPc_6_4',
    cover: 'https://cdn.newuavtech.com/media/agency/thumb_finieco.jpg',
    category: 'video',
  },
  {
    title: 'Serra da Estrela',
    subtitle: '',
    href: '',
    src: '4ZKqWJf42kI',
    cover: 'https://cdn.newuavtech.com/media/agency/thumb_turismo.jpg',
    category: 'video',
  },
  {
    title: 'Serra da Estrela',
    subtitle: '',
    href: '',
    src: 'Kqh8mPyPq5I',
    cover: 'https://cdn.newuavtech.com/media/agency/thumb_turismo2.jpg',
    category: 'video',
  },
  {
    title: 'Fenabel',
    subtitle: '',
    href: '',
    src: 'w3O1XkjKIWA',
    cover: 'https://cdn.newuavtech.com/media/agency/thumb_fenabel.jpg',
    category: 'video',
  },
  {
    title: 'GNR',
    subtitle: '',
    href: '',
    src: 'Zh-HT1QVlXc',
    cover: 'https://cdn.newuavtech.com/media/agency/thumb_gnr.jpg',
    category: 'video',
  },
  {
    title: 'Senvion',
    subtitle: '',
    href: '',
    src: 'NRpDxFFs_Kw',
    cover: 'https://cdn.newuavtech.com/media/agency/thumb_senvion.jpg',
    category: 'video',
  },
  /*{
    title: 'Corrida do Dragão',
    subtitle: '',
    href: '',
    src: '9sMDVY-LEIE',
    cover: 'https://cdn.newuavtech.com/media/agency/thumb_cdd.jpg',
    category: 'video',
  },
  {
    title: 'Só Barroso Automóveis',
    subtitle: '',
    href: '',
    src: 'Pho0XqT5pCg',
    cover: 'https://cdn.newuavtech.com/media/agency/thumb_sobarroso.jpg',
    category: 'video',
  },*/
  {
    title: 'Quinta do Estanho',
    subtitle: '',
    href: '',
    src: 'UTb2VLgpfyI',
    cover: 'https://cdn.newuavtech.com/media/agency/thumb_quintaestanho.jpg',
    category: 'video',
  },

  
];

export default function DigitalAgencyHome() {
  const [itemShow, setItemShow] = useState(10);
  pageTitle('NEWUAV TECH');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero4
        title="Creative People’s Creative Mind"
        subtitle="We deliver best problem solving solution for our client and provide finest finishing product in present and upcoming future."
        scrollDownId="#service"
        //socialLinksHeading="Follow Us"
        //heroSocialLinks={heroSocialLinks}
        phoneNumber="+351 917 541 539"
        email="agency@newuavtech.com"
        backgroundImage='./images/hero_bg_2.jpeg'
      />
      {/* End Hero Section */}

      {/* Start Services Section */}
      
      <Div id="service" className="container">
      <Spacing lg="145" md="80" />
        <SectionHeading
          title="What we provide"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="65" md="45" />
      </Div>
      {/* End Services Section */}

      {/* Start Moving Text Section */}
      <MovingText2 data={serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}

      {/* Start Projects Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Portfolio to explore"
          subtitle="Latest Projects"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${index === 0 || index === 3 || index === 4
                  ? 'col-lg-12'
                  : 'col-lg-12'
                }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                cover={item.cover}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>
        
        <Spacing lg="65" md="40" />
        
      </Div>
      {/* End Projects Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <SectionHeading
          title="Our reputed partner"
          subtitle=""
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <LogoList />
      </Div>
      <Spacing lg="120" md="80" />
      {/* End LogoList Section */}

      <MovingText text=" We are curious, restless, we believe in the sum of knowledge and skills, we design ideas collectively. " variant="cs-type3" />
      <Spacing lg="120" md="10" />


      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}

      <Spacing lg="100" md="10" />
    </>
  );
}
