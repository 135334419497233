import React from 'react';
import Div from '../Div';
import Cta from '../Cta';
import './footer.scss';

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  return (
    <footer className="cs-fooer">

      {/*<Cta
        title="agency@newuavtech.com"
        variant="rounded-0"
      />*/}

      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2024 NEWUAV TECH LDA</Div>

          </Div>
          <Div className="cs-bottom_footer_right">
            <img src='/images/prr-a.png' className='prr-logos' alt='prr'></img>
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
