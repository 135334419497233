import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import VideoModal from '../VideoModal/youtube';
import './portfolio.scss';

export default function Portfolio({ cover, src, title, subtitle, variant }) {
    return (
        <Div className="">
            <VideoModal
                title={title}
                subtitle={subtitle}
                videoSrc={src}
                bgUrl={cover}
                variant="cs-style1 cs-size1"
            />
        </Div>
    );
}



